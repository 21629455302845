import styles from '../../styles/transactionComponents/Transactions.module.scss';
import styles1 from '../../styles/CategoriesComponents/Categories.module.scss';
import { Title } from '../Titles/Titles';
import MainContainer from '../Containers/MainContainer';


import React,{ useState, useEffect } from 'react';
import { getAllCategorys, getAllContacts } from '../../services/contact.service';
import { DateTime } from 'luxon';
import {FilterDate} from '../../services/Filter.service'

const Filters = ({setTransactions,setTimeSpan,lastDate,transactions,setCategoriesValue,setSortingField,setOrder}) => {
  const [allCategories, setAllCategories] = useState('');

  useEffect(() => {
    getAllCategorys().then(res => setAllCategories(res?.data))
  }, []);


  const [firstDate, setFirstDate] = useState(
    DateTime.now()
      .minus({
        day: 1,
      })
      .toISODate()
  );


  return (
    <MainContainer>
      <Title>Search Transactions</Title>
      <div className={styles1.container}>
        <div className={styles1.filters}>
          <div className={styles1.filterContainer}>
            {/* TIME SPAN */}
            <div className={styles1.filter}>
              <label htmlFor="timeSpan">Time Span :</label>
              <select
                name="timeSpan"
                onChange={(e) => {
                  setTimeSpan(e.target.value);
                }}
              >
                <option
                  value={DateTime.now()
                    .minus({
                      days: 7,
                    })
                    .toISODate()}
                >
                  Last 7 days
                </option>
                <option
                  value={DateTime.now()
                    .minus({
                      days: 30,
                    })
                    .toISODate()}
                >
                  Last 28 days
                </option>
                <option
                  value={DateTime.now()
                    .minus({
                      days: 90,
                    })
                    .toISODate()}
                >
                  Last 90 days
                </option>
                <option
                  value={DateTime.now()
                    .minus({
                      days: 365,
                    })
                    .toISODate()}
                >
                  Last 365 days
                </option>
              </select>
            </div>
          </div>

          {/* CATEGORIES */}
          <div className={styles1.filterContainer}>
            <div className={styles1.filter}>
              <label htmlFor="categories">Categories :</label>
              {allCategories ? (
                <select
                  name="categories"
                  onChange={(e) => {
                    setCategoriesValue(e.target.value);
                  }}
                >
                  <option value="">All</option>
                  {allCategories.map((category, index) => {
                    return (
                      <option key={index} value={category.title.rendered}>
                        {category.title.rendered}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <div>Loading...</div>
              )}
            </div>
          </div>

          {/* SORTING FIELD */}
          <div className={styles1.filterContainer}>
            <div className={styles1.filter}>
              <label htmlFor="sortingField">Sorting Field :</label>
              <select
                name="sortingField"
                onChange={(e) => {
                  setSortingField(e.target.value);
                }}
              >
                <option value="data">Date</option>
                <option value="money">Price</option>
              </select>
            </div>
          </div>

          {/* ASC OR DESC */}
          <div className={styles1.filterContainer}>
            <div className={styles1.filter}>
              <label htmlFor="order">Order :</label>
              <select
                name="order"
                onChange={(e) => {
                  setOrder(e.target.value);
                }}
              >
                <option value="">All</option>
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default Filters;
