import MainContainer from "../../components/Containers/MainContainer";
import { Title } from "../../components/Titles/Titles";
import styles from "../../styles/profileComponents/Profile.module.scss";
import { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const Profile = () => {
  let navigate = useNavigate()

  const token = window.localStorage.getItem('token');

  //state
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const body = {
    title: firstName
  };

  async function CategoryNew(){
    await axios.post(`https://maiseguidores.com/wp-json/wp/v2/category/`,{
        title: firstName,
        status: "publish"
        },{
          headers:{
            'Authorization': `Bearer ${token}`, 
            'Content-Type': 'application/json'
          }
        })
    navigate('/categories')
  }

  return (
    <MainContainer>
      <Title>Add Category</Title>
      <form action="submit" onSubmit={(e) => e.preventDefault()}>
        <div className={styles.container}>
          {/* FIRSTNAME */}
          <div className={styles.firstName}>
            <label htmlFor="firstName">Category Name :</label>
            <input
              type="text"
              name="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          {/* LASTNAME */}
          <div className={styles.lastName}>
            <label htmlFor="lastName">Image (indisponivel) : </label>
            <input
              type="text"
              name="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          {/* BUTTON */}
          <button onClick={() => CategoryNew()}>Add Category</button>
          {/*userUpdated && (
            <div style={{ marginTop: "1rem", color: "green" }}>Success</div>
          )}
          {userNotUpdated && (
            <div style={{ marginTop: "1rem", color: "red" }}>Error</div>
          )*/}
        </div>
      </form>
    </MainContainer>
  );
};

export default Profile;
