//STYLES
import styles from "../../styles/transactionComponents/AddTransactionForm.module.scss";
//COMPONENTS
import { Title } from "../Titles/Titles";
import axios from 'axios';

//UTILS
import {useNavigate,useParams } from 'react-router-dom';
import React,{ useEffect, useState,useCallback } from "react";
import { DateTime } from "luxon";
import {getAllCategorys} from '../../services/contact.service.js'
import MainContainer from "../Containers/MainContainer";

const AddTransactionForm = () => {
  const [title, setTitle] = useState("");
  const [money, setMoney] = useState("");
  const [date, setDate] = useState(DateTime.now().toISODate());
  const [info, setInfo] = useState("");

  const [initialCategory, setInitialCategory] = useState('');

  const [categoryExpense, setCategoryExpense] = useState('');

  const [total, setTotal] = React.useState(0);

  const [allCategories, setAllCategories] = useState("");

  const [offSet, setOffset] = React.useState(1);

  const [transactions,setTransactions] = useState({
    books:[],
    allboks: [],
    isLoaded: false
  }) 

  const token = window.localStorage.getItem('token');

  let navigate = useNavigate()

  let id = useParams()?.contactId

  useEffect( ()=>{
    getAllCategorys().then(res =>{
      setAllCategories(res?.data)
    })
  },[allCategories])

  useEffect(()=>{
    async function getCategory() {
      try{
        const response = await axios.get(`https://maiseguidores.com/wp-json/wp/v2/expense/${id}`,{
            headers:{
              'Authorization': `Bearer ${token}`, 
              'Content-Type': 'application/json'
            }
          }).then(res =>{
            setTitle(res?.data?.title?.rendered)
            setMoney(res?.data?.acf?.money)
            setDate(res?.data?.acf?.data)
            setInfo(res?.data?.content?.rendered)
            setTotal(res.headers['x-wp-total']);
            setInitialCategory(res?.data?.acf?.category)

          })
      }catch(e){
        console.log(e)
      }
    }
    getCategory()  
  },[offSet])

  let state = {
    title: title,
    money: parseFloat(money),
    date: date,
    info: info,
    category: categoryExpense
  };

  let handleClick =  useCallback (async(e) => {
    e.preventDefault();
    try{
      await axios.post(`https://maiseguidores.com/wp-json/wp/v2/expense/${id}`,{
        title: title,
        content: info,
        excerpt: "opa",
        status: "publish",
        fields:{
          money: money,
          data: date,
          category: categoryExpense
        },
        },{
          headers:{
            'Authorization': `Bearer ${token}`, 
            'Content-Type': 'application/json'
          }
        })

        navigate('/')

    }catch(e){
      console.log(e)
    }
    

  }, [state]);

  return (
    <MainContainer>
      <div className={styles.container}>
        <Title>Edit Transaction</Title>
        <div className={styles.inner}>
          <input
            type="text"
            placeholder="title"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
          <input
            type="number"
            placeholder="money"
            onChange={(e) => setMoney(e.target.value)}
            value={money}
          />
          <input
            type="date"
            placeholder="date"
            onChange={(e) => setDate(e.target.value)}
            value={date}
          />
          <input
            type="text"
            placeholder="info"
            onChange={(e) => setInfo(e.target.value)}
            value={info}
          />

          {/* CATEGORIES */}
          {allCategories ? (
            <select onChange={(e) => setCategoryExpense(e.target.value)}>
              <option key={initialCategory} value={initialCategory}>{initialCategory}</option>
              {allCategories.map((category, index) => {
                return (
                  <option key={category?.title?.rendered} value={category?.title?.rendered}>
                    {category?.title?.rendered}
                  </option>
                );
              })}
            </select>
          ) : (
            <div>loading...</div>
          )}

          {/* POST TRANSACTION */}
          <button
            onClick={handleClick}
          >
            {"Edit Transaction"}
          </button>

          {/* ERROR */}
          <div style={{ marginBottom: "1rem" }}>
            {/*error &&
              error.response.data.map((err, index) => {
                return (
                  <div style={{ color: "red" }} key={index}>{`${
                    err.instancePath
                  } : ${err.message ? err.message : ""}`}</div>
                );
              })*/}
            {/*isSuccess && <div style={{ color: "green" }}>Success</div>*/}
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default AddTransactionForm;
