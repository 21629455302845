
  export function FilterDate(allContact, startDateTime,lastDateTime){
    const dataFilted = allContact?.filter(a => {

      const dateAtual = new Date(a?.date).getTime()

      return dateAtual >= startDateTime && dateAtual <= lastDateTime
    })

    return dataFilted
  }

  export function FilterCategory(allContact, caregory){
    const dataFilted = allContact?.filter(a => 
      a?.acf?.category === caregory
    )

    return dataFilted
  }

  export function FilterSorting(allContact,orderBy){
    const dataFilted = allContact?.sort((a,b) => {
      if(typeof a?.acf?.[orderBy] === 'string'){
        return a?.acf?.[orderBy].localeCompare(b?.acf?.[orderBy])
      }
      return a?.acf?.[orderBy] - b?.acf?.[orderBy]
    })

    return dataFilted
  }
  export function FilterAsc(allContact,orderBy){
    const dataFilted = allContact?.sort((a,b) => {
      if(orderBy === 'asc'){
        return Number(a?.acf?.money) < Number(b?.acf?.money) ? -1 : 1 
      }
      if(orderBy === 'desc'){
        return Number(a?.acf?.money) > Number(b?.acf?.money) ? -1 : 1 
      }
    })

    return dataFilted
  }





