import MainContainer from '../components/Containers/MainContainer';
import Searchbar from '../components/homeComponents/Searchbar';
import { Title } from '../components/Titles/Titles';
import CategoryCard from '../components/Cards/CategoryCard';
import TransactionCard from '../components/Cards/TransactionCard';
import styles from '../styles/homeComponents/Home.module.scss';
import HomeProfile from '../components/homeComponents/HomeProfile';
import { getAllContacts } from '../services/contact.service.js';
import Pagination from '../components/Navbar/Pagination';

import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Filters from '../components/Navbar/Filters';
import {
  FilterCategory,
  FilterDate,
  FilterSorting,
  FilterAsc
} from '../services/Filter.service';

const Home = () => {
  const token = window.localStorage.getItem('token');

  const [timeSpan, setTimeSpan] = useState(
    DateTime.now()
      .minus({
        days: 7,
      })
      .toISODate(),
  );

  const [lastDate, setLastDate] = useState(
    DateTime.now()
      .plus({
        day: 1,
      })
      .toISODate(),
  );

  const startDateTime = new Date(timeSpan).getTime();
  const lastDateTime = new Date(lastDate).getTime();

  //LATEST TRS
  const [totalMoney, setTotalMoney] = useState(0);
  const [category, setCategory] = useState('');
  const [offSet, setOffset] = React.useState(1);
  const [total, setTotal] = React.useState(0);
  const [limit, setLimit] = React.useState(25);
  const [categoriesValue, setCategoriesValue] = useState('');
  const [sortingField, setSortingField] = useState('data');
  const [order, setOrder] = useState('');

  const [transactions, setTransactions] = useState({
    books: [],
    allboks: [],
    isLoaded: false,
  });

  const [filtedTransactions, setFiltedTransactions] = React.useState([]);

  useEffect(() => {
    getAllContacts(`?per_page=${limit}&page=${offSet}`)
      .then((res) => {
        setTransactions({
          books: res,
          allboks: res?.data,
          isLoaded: false,
        });
        
        setTotal(res.headers['x-wp-total']);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    async function getCategory() {
      try {
        await axios
          .get(`https://maiseguidores.com/wp-json/wp/v2/category/`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          })
          .then((res) => {
            setCategory(res?.data);
          });
      } catch (e) {
        console.log(e);
      }
    }
    getCategory();
  }, []);

  useEffect(() => {
    if (transactions?.allboks) {
      let newArray = [...transactions.allboks];
      if (startDateTime && lastDateTime) {
        newArray = FilterDate(
          transactions?.allboks,
          startDateTime,
          lastDateTime,
        );
      }
      if (categoriesValue) {
        newArray = FilterCategory(newArray, categoriesValue);
      }
      if (sortingField) {
        newArray = FilterSorting(newArray, sortingField);
      }
      if (order) {
        newArray = FilterAsc(newArray,order);
      }
      setFiltedTransactions(newArray);
    }
  }, [
    categoriesValue,
    sortingField,
    transactions,
    timeSpan,
    order
  ]);

  useEffect(()=>{
    setTotalMoney(
      filtedTransactions.reduce(
        (value, currentValue) =>
          Number(currentValue?.acf?.money) + Number(value),
        0,
      ),
    );
  },[filtedTransactions])

  const regex = new RegExp('(?<=<p>).*(?=</p>)');

  return (
    <MainContainer optionClass={styles.container}>
      <div className={styles.main}>
        {/* SEARCHBAR */}
        <div className={styles.searchbar}>
          <Searchbar />
        </div>

        {/* TRANSACTIONS */}
        <div className={styles.transactions}>
          <Title>
            Latest Transactions{' '}
            <Link to={`/transactions/new`} className="">
              <span className="btn btn-primary ms-2">
                New <i className="fa fa-plus-circle me-2" />
              </span>
            </Link>
          </Title>
          <div></div>

          <div className="mr-10">
            <Filters
              setOrder={setOrder}
              setSortingField={setSortingField}
              setCategoriesValue={setCategoriesValue}
              transactions={transactions}
              setTransactions={setTransactions}
              setTimeSpan={setTimeSpan}
              lastDate={lastDate}
            />
          </div>
          <p style={{ color: totalMoney > 0 ? 'green' : 'red' }}>
            Total:{' '}
            {new Intl.NumberFormat('pt-Br', {
              style: 'currency',
              currency: 'BRL',
            }).format(totalMoney)}
          </p>
          <div className={styles.content}>
            {/* LATEST TRANSACTIONS */}
            {transactions &&
              filtedTransactions.map((transaction, index) => {
                return (
                  <TransactionCard
                    status={transaction?.acf?.status}
                    key={index}
                    category={transaction?.acf?.category}
                    date={DateTime.fromISO(transaction?.acf?.data).toISODate()}
                    money={new Intl.NumberFormat('pt-Br', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(transaction?.acf?.money)}
                    description={transaction?.content?.rendered.match(regex)}
                    title={transaction?.title?.rendered}
                    id={transaction?.id}
                  />
                );
              })}
          </div>
          <div className="d-flex">
            {
              <Pagination
                limit={limit}
                total={total}
                offSet={offSet}
                setOffSet={setOffset}
              />
            }
          </div>
        </div>
      </div>
      <div className={styles.profile}>
        <HomeProfile />
      </div>
    </MainContainer>
  );
};

export default Home;
