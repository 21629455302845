import styles from "../styles/CategoriesComponents/Categories.module.scss";
import { Title } from "../components/Titles/Titles";
import CategoryCard from "../components/Cards/CategoryCard";
import MainContainer from "../components/Containers/MainContainer";

import { DateTime } from "luxon";
import { useState, useEffect } from "react";
import { getAllCategorys, getAllContacts } from "../services/contact.service";
import {Link} from 'react-router-dom'

const Categories = () => {
  //SEARCH FILTERS
  const [timeSpan, setTimeSpan] = useState(
    DateTime.now()
      .minus({
        days: 7,
      })
      .toISODate()
  );
  const [allCategories, setAllCategories] = useState("");
  const [skip, setSkip] = useState(0);

  useEffect( ()=>{
    getAllCategorys().then(res =>{
      setAllCategories(res?.data)
    })
  },[allCategories])

  return (
    <MainContainer>
      <Title>All Categories <Link to={`/category/new`} className="">
            <span className="btn btn-primary ms-2">New <i className="fa fa-plus-circle me-2"/></span>
          </Link></Title>
      <div className={styles.container}>
            {allCategories ? 
            (allCategories.map((category)=>{
              return(
                <CategoryCard
                  category={category.title.rendered}
                  id={category.id}
                />
              )
            })) 
            : (<div>Loading...</div>)}
      </div>
    </MainContainer>
  );
};

export default Categories;
