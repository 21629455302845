//STYLES
import styles from "../styles/settingsComponents/Settings.module.scss";

//COMPONENTS
import { Title } from "../components/Titles/Titles";
import MainContainer from "../components/Containers/MainContainer";

//UTILS
import React,{ useState } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Settings = () => {

  const [isLoading, setIsLoading] = useState('')
  const [error, setError] = useState('')
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const token = window.localStorage.getItem('token');
  React.useEffect(()=>{
    async function autoLogin(){
      if (token) {
        try {
          const response = await axios.post(`https://maiseguidores.com/wp-json/jwt-auth/v1/token/validate`,{
          headers:{
            'Authorization': `Bearer ${token}`, 
            'Content-Type': 'application/json'
          }
        })
        if(!response.ok) throw new Error('Token inválido');
        } catch(err){
        }
      } 
    }
    autoLogin()
  }, [token] );

  async function handleSubmit(event){
    event.preventDefault();
    try{
      const tokenRes = await axios.post(`https://maiseguidores.com/wp-json/jwt-auth/v1/token`,{
        username: username,
        password: password,
        headers:{
            'Content-Type': 'application/json'
          }
      })
      const token = tokenRes.data.token;
      window.localStorage.setItem('token',`${token}`);
      navigate('/')
    } catch(e){
      const login = document.querySelector('.loginLogin')
      const password = document.querySelector('.loginPassword')

      login.focus();
      password.focus();
      setError('Ops.. Acesso não autorizado')
    }
  }

  return (
    <MainContainer>
      <Title>Settings</Title>
      <form action="submit" onSubmit={handleSubmit}>
        <div className={styles.container}>
          {/* OLD PW */}
          <div className={styles.password}>
            <label htmlFor="username">Username : </label>
            <input
            className="loginLogin"
              type="text"
              name="username"
              value={username}
              autoComplete="username"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className={styles.password}>
            {/* NEW PW */}
            <label htmlFor="Password">Password : </label>
            <input
            className="loginPassword"
              type="password"
              name="Password"
              autoComplete="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button>
            {isLoading ? "Loading" : "Login"}
          </button>
        </div>
        {error && (
          <div style={{ marginTop: "1rem", color: "red" }}>
            {error}
          </div>
        )}
      </form>
    </MainContainer>
  );
};

export default Settings;
