import "./styles/App.scss";
import { Routes, Route, Outlet } from "react-router-dom";

//COMPONENTS
import PageContainer from "./components/Containers/PageContainer";
import Navbar from "./components/Navbar/Navbar";
import MobileNavbar from "./components/Navbar/MobileNavbar";
import MainContainer from "./components/Containers/MainContainer";
import ProtectedRoutes from "./components/ProtectedRoutes";
import IsAuth from './Login/IsAuth';
import CategoryNew from './components/Categories/CategoryNew'

//PAGES
import Home from "./pages/Home";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import Categories from "./pages/Categories";

//REACT QUERY
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClientProvider,QueryClient } from "react-query";
import AddTransactionForm from "./components/transactionComponents/AddTransactionForm";
import EditTransactionForm from "./components/transactionComponents/EditTransactionForm";

function App() {

  const token = window.localStorage.getItem('token');
  const queryClient = new QueryClient()
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <PageContainer optionClass={"pageContainer"}>
          <Navbar />
          <Routes>
            {/* AUTH PAGE */}
            {/* PROTECTED ROUTES */}
            <Route element={<ProtectedRoutes />}>
              {/* HOME */}
              <Route path="/" element={<IsAuth><Home /></IsAuth>} />
              {/* PROFILE */}
              <Route path="/profile" element={<IsAuth><Profile /></IsAuth>} />
              {/* TRANSACTIONS */}
              <Route path="/transactions/new" element={<IsAuth><AddTransactionForm /></IsAuth>} />
              <Route path="/transactions/edit/:contactId" element={<IsAuth><EditTransactionForm /></IsAuth>} />
              {/* CATEGORIES */}
              <Route path="/categories" element={<IsAuth><Categories /></IsAuth>} />
              <Route path="/category/new" element={<IsAuth><CategoryNew /></IsAuth>} />
              <Route path="/category/edit/:contactId" element={<IsAuth><CategoryNew /></IsAuth>} />
              <Route path="/login" element={<Login />} />
              {/* Login */}


              {/* 404 */}
              <Route
                path="/*"
                element={
                  <MainContainer>
                    <span style={{ fontSize: "1.2rem" }}>
                      404 Page Not Found
                    </span>
                  </MainContainer>
                }
              />
            </Route>
          </Routes>
        </PageContainer>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </div>
  );
}

export default App;
