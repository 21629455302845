import MainContainer from "./Containers/MainContainer";
import { Title } from "./Titles/Titles";

import { Outlet, Navigate } from "react-router-dom";
import { useContext } from "react";

const ProtectedRoutes = () => {
  const authHandler = () => {
    switch (true) {
      default:
        return (
          <MainContainer>
            <Title>Loading..</Title>
          </MainContainer>
        );
      case true:
        return <Outlet />;
      case false:
        return <Navigate to="/" />;
    }
  };

  return authHandler();
};

export default ProtectedRoutes;
