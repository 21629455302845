import axios from 'axios';

const token = window.localStorage.getItem('token');

export const instanceContact = axios.create({
    baseURL: 'https://maiseguidores.com/wp-json/wp/v2/expense/',
    headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json' 
    },
});

export const getAllContacts = async (url) => {
    return await instanceContact.get(url)
}

export const createContact = async (contact) => {
    const data = await instanceContact.post('', contact);
    return data
}

export const updateContact = async (contact, config) => {
    return await instanceContact.put(`${contact.id}`, config);
}

export const deleteContact = async (contact) => {
    return await instanceContact.delete(`${contact}`);
}

export const instanceCategory = axios.create({
    baseURL: 'https://maiseguidores.com/wp-json/wp/v2/category/',
    headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json' 
    },
});

export const getAllCategorys = async (url) => {
    return await instanceCategory.get(url)
}

export const createCategorys = async (contact) => {
    const data = await instanceCategory.post('', contact);
    return data
}

export const updateCategorys = async (contact, config) => {
    return await instanceCategory.put(`${contact.id}`, config);
}

export const deleteCategorys = async (id) => {
    return await instanceCategory.delete(`${id}`);
}