//STYLES
import styles from "../../styles/transactionComponents/AddTransactionForm.module.scss";
//COMPONENTS
import { Title } from "../Titles/Titles";
import axios from 'axios';

//UTILS
import { Link, Navigate, useNavigate } from 'react-router-dom';
import React,{ useEffect, useState,useCallback } from "react";
import { DateTime } from "luxon";
import {getAllContacts} from '../../services/contact.service.js'
import MainContainer from "../Containers/MainContainer";

const AddTransactionForm = () => {
  const [title, setTitle] = useState("");
  const [money, setMoney] = useState("");
  const [date, setDate] = useState(DateTime.now().toISODate());
  const [info, setInfo] = useState("");
  const [status, setStatus] = useState("");

  const [category, setCategory] = useState('');
  const [categoryExpense, setCategoryExpense] = useState('');
  const [total, setTotal] = React.useState(0);
  const [offSet, setOffset] = React.useState(1);
  const [transactions,setTransactions] = useState({
    books:[],
    allboks: [],
    isLoaded: false
  }) 

  useEffect(()=>{
    async function getCategory() {
      try{
        await axios.get(`https://maiseguidores.com/wp-json/wp/v2/category/`,{
            headers:{
              'Authorization': `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvbWFpc2VndWlkb3Jlcy5jb20iLCJpYXQiOjE2NTcwNDQyMzgsIm5iZiI6MTY1NzA0NDIzOCwiZXhwIjoxNjU3MTMwNjM4LCJkYXRhIjp7InVzZXIiOnsiaWQiOiIxIn19fQ.wqaqbCOGs2hCCw9i6Gga7NDpu4KtVgUnxO0TsKudvu8`, 
              'Content-Type': 'application/json'
            }
          }).then(res =>{
            setCategory(res?.data)
          })

      }catch(e){
        console.log(e)
      }
    }
    getCategory()  
  },[])

  

  const token = window.localStorage.getItem('token');
  let navigate = useNavigate()

  useEffect(()=>{
    getAllContacts().then(res=>{
      setTransactions({
        books: res,
        allboks: res.data,
        isLoaded: false
      })

      setTotal(res.headers['x-wp-total']);
    })
      .catch(function (error) {
        console.log(error);
      });
  }, [offSet]);



  let state = {
    title: title,
    money: parseFloat(money),
    date: date,
    info: info,
    category: categoryExpense
  };

  let handleClick =  useCallback (async(e) => {
    e.preventDefault();
    try{
      await axios.post(`https://maiseguidores.com/wp-json/wp/v2/expense/`,{
        title: state?.title,
        content: state?.info,
        excerpt: "opa",
        status: "publish",
        fields:{
          money: state?.money,
          data: state?.date,
          category: categoryExpense,
          status: status
        },
        },{
          headers:{
            'Authorization': `Bearer ${token}`, 
            'Content-Type': 'application/json'
          }
        })

        navigate('/')

    }catch(e){
      console.log(e)
    }
    

  }, [state]);

  return (
    <MainContainer>
      <div className={styles.container}>
        <Title>Add a Transaction</Title>
        <div className={styles.inner}>
          <input
            type="text"
            placeholder="title"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
          <input
            type="number"
            placeholder="money"
            onChange={(e) => setMoney(e.target.value)}
            value={money}
          />
          <input
            type="date"
            placeholder="date"
            onChange={(e) => setDate(e.target.value)}
            value={date}
          />
          <input
            type="text"
            placeholder="info"
            onChange={(e) => setInfo(e.target.value)}
            value={info}
          />

          <select value={status} onChange={(e) => setStatus(e.target.value)}>
            <option value='' disabled>Choose</option>
            <option value="income">Income</option>
            <option value="expense">Expense</option>
          </select>

          {/* CATEGORIES */}
          {category ? (
            <select value={categoryExpense} onChange={(e) => setCategoryExpense(e.target.value)}>
              <option value='' disabled>Category</option>
              {category.map((category, index) => {
                return (
                  <option key={index} value={category?.title?.rendered}>
                    {category?.title?.rendered}
                  </option>
                );
              })}
            </select>
          ) : (
            <div>loading...</div>
          )}

          {/* POST TRANSACTION */}
          <button
            onClick={handleClick}
          >
            {"Add Transaction"}
          </button>

          {/* ERROR */}
          <div style={{ marginBottom: "1rem" }}>
            {/*error &&
              error.response.data.map((err, index) => {
                return (
                  <div style={{ color: "red" }} key={index}>{`${
                    err.instancePath
                  } : ${err.message ? err.message : ""}`}</div>
                );
              })*/}
            {/*isSuccess && <div style={{ color: "green" }}>Success</div>*/}
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default AddTransactionForm;
